/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "theme/colors";


[hidden] {
  display: none !important;
}

.custom-options .action-sheet-button-inner {
  justify-content: center;
}

.tel-custom-options {
  .action-sheet-button-inner {
    justify-content: center;
  }

  .action-sheet-selected.sc-ion-action-sheet-md {
    font-weight: normal;
  }
}

.text-blue {
  color: #0075ba !important;
}

.text-justify {
  text-align: justify;
}

.list-no-padding {
  & ol {
    padding-left: 5%;
  }

  & ul {
    padding-left: 5%;
  }
}


.list-padding {
  & ol {
    padding-left: 5%;

    & li {
      padding-bottom: 1%;
    }
  }

  & ul {
    padding-left: 5%;

    & li {
      padding-bottom: 1%;
    }
  }
}

.welcome-banner {
  display: block;
  width: 100vw;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  padding: 5px 0 5px 0;
}

.mat-expansion-panel-header-title {
  color: #0075ba;
}

.mat-expansion-indicator::after {
  border-color: #0075ba;
}

.header-form {
  padding-top: 5vh;
  padding-bottom: 2em;

  ion-label {
    font-size: 2em;
    font-weight: bold;
    color: #0075ba;
  }
}

.main-menu {
  max-width: 700px;
}

.new-main-menu {
  max-width: 500px;
}

.menu-list-item {
  min-width: 75px;
  margin: 8px;
}


.menu-item {
  fill: white;
  height: 100%;
  width: 100%;
  margin: auto;
  --background: white;
  --border-width: 0;
  --box-shadow: none;
}

.new-menu-item {
  fill: transparent;
  height: 100%;
  width: 100%;
  margin: auto;
  background: transparent;
  --background: transparent;
  --border-width: 0;
  --box-shadow: none;
}

.menu-text {
  font-size: 18px;
  font-weight: 700;
  color: #0075ba;
}

.title-text {
  color: #0075ba;
}

.bold-text {
  font-size: 40px;
  font-weight: bold;
}

.alert-info {
  .alert-head {
    background-color: #0075ba;
  }

  .alert-message {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 20px !important;

    p {
      text-align: justify;
    }
  }

  .alert-title {
    color: white;
  }
}

.alert-info-large {
  @extend .alert-info;

  .alert-wrapper {
    max-width: 90vw !important;
    width: 500px !important;
  }
}

.alert-danger {
  .alert-head {
    background-color: #eb445a;
  }

  .alert-message {
    padding-left: 24px !important;
    padding-right: 24px !important;
    padding-top: 20px !important;

    p {
      text-align: justify;
    }
  }


  .alert-title {
    color: white;
  }
}

.alert-danger-large {
  @extend .alert-danger;

  .alert-wrapper {
    max-width: 90vw !important;
    width: 500px !important;
  }
}


.bg-gray {
  background: rgb(236, 236, 236);
  color: black;
}

.item-bg-gray {
  --ion-background-color: rgb(236, 236, 236) !important;
  background: rgb(236, 236, 236);
  color: black;
}

.item-transparent {
  background: transparent;
  --background: transparent;
  --ion-item-background: transparent;
}

.item-no-padding {
  padding: 0;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.border-input {
  --border-width: 2px;
  --border-style: dotted;
  --background: whitesmoke;
  margin: 5px 5px 5px 0;
}

@media screen and (max-width: 500px) {
  .menu-text {
    font-size: 3vw !important;
  }

  .menu-image {
    max-height: 25vw;
    max-width: 25vw;
  }
}

.menu-image {
  width: 125px;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

ion-datetime {
  color: rgba(0, 0, 0);
}

ion-label,
ion-item {
  --color: #333333;
}

.grey-btn {
  --background: rgba(0, 0, 0, 0.7);
}

.label-stacked {
  font-size: 20px;
}

.header-input {
  max-width: 50vw;
  min-width: 280px;
  margin: auto;

}

.nodata {
  text-align: center;
  padding: 10px;
}

.text-title {
  text-align: center;
  font-weight: bold !important;
}

.required:after {
  color: red;
  content: " *";
}

.note {
  font-size: 0.75rem;
  margin-top: 4px;
  margin-bottom: 8px;
  color: var(--ion-color-step-550, #737373)
}

.note:before {
  color: red;
  content: "* ";
}

.table {
  table {
    width: 100%;
  }

  // th {
  //   text-align: center !important;
  //   vertical-align: middle !important;
  // }
  // td{
  //   text-align: center;
  // }
  // .mat-sort-header-container {
  //   justify-content: center !important;
  // }

  mat-cell {
    display: block;
    min-height: auto;
  }

  mat-header-cell {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54)
  }
}

ion-card {
  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: 0 !important;
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: 0 !important;
  }
}

ion-modal {
  &.bottom-end {
    align-items: flex-end;
    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }
}

.small-action-btn {
  width: 30px;
  height: 30px;
  --padding-bottom: 2px;
  --padding-end: 2px;
  --padding-start: 2px;
  --padding-top: 2px;
}

.bottom-menu::part(content) {
  --background: #0075ba;
  border-radius: 10px 10px 0 0;
  height: 80px;
  bottom: 0;
  position: absolute;
  display: block;
}

.profile-image-menu {
  .popover-wrapper {
    --background: #0075ba;
    border-radius: 10px;
    height: 80px;
    width: 75vw;
  }
}

.biometric-reminder-modal::part(content) {
  max-width: 340px;
  min-width: 320px;
  width: auto;
  height: 280px;
  border-radius: 4px;
  -webkit-box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}

.e-card-modal::part(content) {
  min-height: 200px;
  height: fit-content;
}

.investment-edit {
  --background: transparent;
  --box-shadow: none;
}

.investment-change::part(content) {
  --background: transparent;
  border-radius: 10px;
  left: 0 !important;
  height: auto;
  width: 100%;
}

.common-alert::part(content) {
  --background: transparent;
  border-radius: 10px;
  max-width: 80vw;
}

.toast-download::part(message) {
  overflow-wrap: anywhere;
}

.mat-expansion-panel-header {
  height: auto;
  min-height: 48px;
}

.video-banner-card {
  background: transparent;
  --background: transparent;
  box-shadow: none !important;
}

.video-banner-image {
  width: 100%;
  height: auto;
}

.text-upper {
  text-transform: uppercase;
}

.deactivate-acc-text {
  @extend .alert-info;
  text-align: justify;
}

// .custom-loading {
//   --background: url("/assets/icon/logo.png");
//   --width: 80px;
//   --height: 80px;
//   --max-width: 150px;
//   --max-height: 150px;
// }

// .loading-wrapper {
//   -webkit-box-shadow: none !important;
//   box-shadow: none !important;
//   background-position: center !important;
//   background-repeat: no-repeat !important;
//   background-size: cover !important;
// }

// .loading-wrapper:before {
//   position: absolute;
//   content: "";
//   width: 100%;
//   height: 100%;
//   border-radius: 100%;
//   border-bottom: 0 solid #ffffff05;

//   box-shadow: -3px 5px 5px #93a6ff40 inset, 0 1px 2px 2px #ffffff91 inset, 0 -2px 5px #ffffff80 inset,
//     0 -3px 5px #ffffffbb inset, 0 2px 0px #32fdfd, 0 2px 3px #00eeff, 0 5px 5px #2063ff90, 0 5px 5px #02008560,
//     0 3px 3px 5px #086bff59;
//   filter: blur(3px);
//   animation: 2s rotate linear infinite;
// }

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.custom-disabled-ion-range {
  display: flex;
  flex: 1;
  --knob-size: 8px;
  --knob-background: none;
  --knob-box-shadow: none;
}

.custom-disabled-ion-range ion-range {
  --knob-size: 8px;
  --knob-background: none;
  --knob-box-shadow: none;
}

ion-header {
  background: white;
}

@media only screen and (max-width: 375px) {
  .custom-padding-sm-none {
    padding: 0px;
  }
}
// https://ionicframework.com/docs/theming/color-generator
:root {
    --ion-color-bluepdl: #0075ba;
    --ion-color-bluepdl-rgb: 0, 117, 186;
    --ion-color-bluepdl-contrast: #ffffff;
    --ion-color-bluepdl-contrast-rgb: 255, 255, 255;
    --ion-color-bluepdl-shade: #0067a4;
    --ion-color-bluepdl-tint: #1a83c1;
}
.ion-color-bluepdl {
    --ion-color-base: var(--ion-color-bluepdl) !important;
    --ion-color-base-rgb: var(--ion-color-bluepdl-rgb) !important;
    --ion-color-contrast: var(--ion-color-bluepdl-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-bluepdl-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-bluepdl-shade) !important;
    --ion-color-tint: var(--ion-color-bluepdl-tint) !important;
}
